body {
    margin: 0;
    overflow: hidden;
    font-family: Arial, sans-serif;
}
  
  .canvas {
    background-color: #fafafa;
  }
  
  .canvas-container {
    position: relative;
    flex-grow: 1;
    overflow: hidden;
  }

  .zoom-controls {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .zoom-button {
    width: 30px;
    height: 30px;
    font-size: 20px;
    background-color: #f0f1f5;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }