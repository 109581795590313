.table-component {
  width: 100%;
  border-radius: 14px; /* Apply the border radius to all four corners */
  border-top: 1px solid var(--Pink---light, #FFD0D0);
  overflow: hidden;
  border-bottom: 1px solid #ddd; /* Add a bottom border to the component wrapper */
  padding-bottom: 4px; /* Small padding to push the last row content away from the rounded edge */
}

table {
  width: 100%;
  border-collapse: collapse;
}

td {
  border: 1px solid #ddd; /* Grey borders for non-header cells */
  padding: 8px;
  text-align: left;
  font-size: 14px;
  overflow: hidden;
}


th {
  border-top: 2px solid var(--Pink---light, #FFD0D0); /* Pink top border */
  border-right: 1px solid var(--Pink---light, #FFD0D0);
  border-bottom: 1px solid var(--Pink---light, #FFD0D0);
  border-left: 1px solid var(--Pink---light, #FFD0D0);
  background: var(--Grey---light, #FDFBFB);
  padding: 5px 60px 5px 15px;
  font-weight: 100;
}

input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  background: none; /* Make input background blend with cell */
}

tr:last-child td {
  text-align: left;
  color: #888;
  cursor: pointer;
  padding: 8px;
  border-bottom: none;
  padding: 4px 0 0 4px;
  font-size: 12px;
  margin-top: 2px;
}


button {
  background-color: #e0e0e0;
  border: none;
  padding: 5px;
  cursor: pointer;
  font-size: 12px;
}
