.toolbar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 82px;
  }
  
  .tool-button, .ai-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .tool-button .tool-icon:hover {
    background-color: #e0e0e0;
  }
  
  .tool-icon {
    width: 65px;
    height: 65px;
    border-radius: 14px;
  }

  .tool-button .tool-icon.selected {
    background: var(--Turquoise---light, #B1ECEE) !important;
  }
  
  
  .popover-wrapper {
    position: relative;
  }
  
  .popover-content {
    display: flex; /* Allow the popover to resize based on its content */
    z-index: 1000;
    width: 100%;
    overflow: visible; /* Ensure content is not clipped */
  }

  .custom-tippy {
    background-color: transparent !important; /* Remove default background */
    box-shadow: none !important;              /* Remove default shadow */
    padding: 0;                               /* Remove default padding if needed */
    max-width: 100% !important;
  }
  