.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px 20px; */
    background-color: #f8f9fa;
    border-bottom: 1px solid #ddd;
  }
  
  .workspace-name {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    width: 100%;
    margin-left: 30px;
  }
  
  .header-actions {
    display: flex;
    align-items: center;
  }
  
  .avatars {
    display: flex;
    margin-right: 15px;
  }
  
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-left: -10px;
    border: 2px solid #fff;
  }
  
  .sharing-button {
    color: #000;
    padding: 5px 10px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
    background: none;
    font-weight: bold;;
  }
  
  .leave-button {
    display: flex;
    background-color: #EE7F7F;
    border: none;
    cursor: pointer;
    height: 60px;
    width: 100px;
    justify-content: center;
    font-weight: bold;
  }

  .leave-button button {
    background: none;
    color: #000;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    font-weight: bold;;
  }
  