.elements-popover-wrapper {
    min-width: 620px;
    min-height: 610px;
    padding: 20px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
    z-index: 100;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .elements-popover-wrapper > div {
    display: flex;
    flex-direction: row;
  }
  
  .popover-header {
    margin-bottom: 16px;
  }
  
  .elements-popover-search {
    width: 50%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .elements-popover-section {
    margin-bottom: 20px;
  }
  
  .elements-popover-section h3 {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .popover-item-group {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  
  .elements-popover-item {
    display: flex;
    align-items: center;
    transition: background-color 0.2s;
    border: 1px solid var(--Grey---bold, #D9D9D9);
    overflow: hidden;
    border-radius: 10px;
  }

  .elements-popover-item.clickable {
    cursor: pointer;
  }
  
  .elements-popover-item.clickable:hover {
    background-color: #eee;
  }
  
  .item-thumbnail {
    font-size: 24px;
    margin-right: 8px;
  }
  
  .item-info {
    display: flex;
    width: 112px;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }
  
  .item-title {
    display: flex;
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    color: #333;
    /* padding: 10px; */
    /* justify-content: left;  */
    align-items: center;
    /* width: 100%; */
  }
  
  .item-description {
    height: 56px;
    font-size: 12px;
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    padding: 10px 10px 40px 10px;
    margin: 0;
    border-bottom: 1px solid var(--Grey---bold, #D9D9D9);
    background: var(--Grey---light, #FDFBFB);
  }
  .item-header {
    display: flex;
    align-items: center; /* Align the icon and title vertically */
    padding: 3px;
  }