.share-modal {
    font-family: Arial, sans-serif;
    border-radius: 16px;
    background-color: #ffffff;
  }

  .share-modal-content {
    padding: 20px;
  }
  
  .share-modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modal-logo {
    width: 60px;
    height: 50px;
    margin-right: 10px;
    border-radius: 0 0 18px 0;
  }
  
  h2 {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }
  
  .invite-section {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .invite-input {
    flex: 1;
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    outline: none;
  }
  
  .permission-dropdown {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #f9f9f9;
  }
  
  .invite-button {
    padding: 8px 16px;
    font-size: 14px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .members-section {
    margin-top: 10px;
  }
  
  .member {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .member-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .member-name {
    flex: 1;
    font-size: 14px;
    color: #333;
  }
  
  .role-dropdown {
    padding: 6px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #f9f9f9;
  }
  