/* .app-container {
  display: flex;
  height: 100vh;
} */

.toolbar {
  width: 200px;
  background-color: #f0f0f0;
}

.canvas {
  flex: 1;
  overflow: auto;
  background-color: #fafafa;
  position: relative;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Main content layout for toolbar and canvas */
.main-content {
  display: flex;
  flex-grow: 1;
}