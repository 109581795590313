.popover-wrapper-custom {
    
    background-color: white;
    border-radius: 32px;
    font-family: Arial, sans-serif;
    color: #333;
    z-index: 100;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .popover-wrapper-custom > div {
    display: flex;
    flex-direction: row;
  }